/* eslint-disable jsx-a11y/iframe-has-title */
import { useState, useRef, useEffect } from "react";
import {
  Main,
  Container,
  Wrapper,
  LeftColumn,
  Paragraph,
  RightColumn,
  Image,
} from "../../globalStyles";
import { Helmet } from "react-helmet";
import carrefourBar from "../../assets/images/carrefour/bar.jpg";
import carrefourClients from "../../assets/images/carrefour/clients.jpg";
import { Link } from "../../globalStyles";
// import CurrentMenu from "../../assets/images/carrefour/carte-du-moment.pdf";

const Carrefour = () => {
  const refContainer = useRef(null);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  function noteImgSize() {
    const { offsetWidth, offsetHeight } = refContainer.current;
    setImageSize({ width: offsetWidth, height: offsetHeight });
  }

  useEffect(() => {
    const handleWindowResize = () => noteImgSize();
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <Main>
      <Helmet>
        <title>Carrefour du Village</title>
        <meta
          name="description"
          content="Le Carrefour, c’est le lieu animé au coeur du village, ouvert à tous, où il fait bon partager échanger et déguster des produits du terroir."
        />
        <meta
          property="og:image"
          content="https://www.palpvillage.ch/og/carrefour-du-village.jpg"
        />
      </Helmet>
      <Container>
        <Wrapper>
          <LeftColumn>
            <h2>
              <span>Restaurant Carrefour</span>
              <br />
              <span>du Village Bruson</span>
            </h2>
            <h4>Restaurant, café, résidences d'artistes et lieu de création</h4>
            <Paragraph>
              <p>
                Le Carrefour à Bruson, c’est le lieu animé au coeur du village,
                ouvert à tous. La cuisine est tournée exclusivement vers les
                produits frais, locaux et de saison, sous la spatule de Sabine
                Besse.
              </p>
              <p>
                C’est également un café et un lieu de création, de résidences
                d’artistes et chercheurs. On y organise des projections, des
                concerts, ainsi que des expositions, des ateliers et toutes
                sortes d’événements.
              </p>
              <p>
                Lieu propice aux bons repas, version recettes Grand-Maman, aux
                rencontres, aux discussions, à la lecture et à la création, on
                s’y sent comme à la maison!
              </p>
            </Paragraph>
            <Paragraph>
              <h4>Horaires</h4>
              <p>
                Lundi: fermé
                <br />
                Mardi: 10h00 – 16h00
                <br />
                Mercredi: 10h00 – 18h30
                <br />
                Jeudi: 10h00 – 22h00
                <br />
                Vendredi et samedi: 10h00 – 23h00
                <br />
                Dimanche: fermé
              </p>
            </Paragraph>
            <Paragraph>
              <p>
                Horaires cuisine midi: 12h00 – 14h00
                <br />
                Horaires cuisine soir: 18h30 – 20h30
              </p>
            </Paragraph>
            {/* <Paragraph>
              <p style={{ color: "#ff4d6d", fontStyle: "italic" }}>
                Le Carrefour sera fermé du 23 septembre au 7 octobre
              </p>
            </Paragraph> */}
            {/* <Paragraph>
              <h4>Menu</h4>
              <Link href={CurrentMenu} target="_blank" rel="noreferrer">
                Carte du moment
              </Link>
            </Paragraph> */}
            <Paragraph>
              <h4>Réservations</h4>
              <p>
                Téléphone: +41 027 565 50 50
                <br />
                Email: carrefour@palpfestival.ch
              </p>
            </Paragraph>
            {/* <Paragraph>
              <h4>Ateliers</h4>
              <Link href="/evenements-et-ateliers">Programme</Link>
            </Paragraph> */}
            <Paragraph>
              <h4>Adresse</h4>
              <p>Chemin de Crètadzera 17, 1934 Bruson</p>
            </Paragraph>
            <Paragraph>
              <h4>Facebook</h4>
              <p>
                <Link
                  href="https://www.facebook.com/carrefour.du.village.bruson"
                  target="_blank"
                  rel="noreferrer"
                >
                  www.facebook.com/carrefour.du.village.bruson
                </Link>
              </p>
            </Paragraph>
          </LeftColumn>
          <RightColumn>
            <Image>
              <img src={carrefourBar} alt="Le bar du Carrefour" />
              <figcaption>© PALP Village</figcaption>
            </Image>
            <Image>
              <img
                ref={refContainer}
                onLoad={noteImgSize}
                src={carrefourClients}
                alt="Les clients du Carrefour"
              />
              <figcaption>© PALP Village</figcaption>
            </Image>
            <iframe
              width={imageSize.width}
              height={imageSize.height}
              frameborder="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Restaurant%20Carrefour%20%20du%20village,%20Chemin%20de%20Cr%C3%A8tadzera%2017,%201934%20Bruson+(Restaurant%20Carrefour%20du%20village,)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
          </RightColumn>
        </Wrapper>
      </Container>
    </Main>
  );
};

export default Carrefour;
